<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="7" xs="5" md="5">
          <h1 class="primary--text">Processing Jobs</h1>
        </v-col>

        <v-col cols="6" xs="3" md="3" offset-md="4">
          <v-text-field
            v-model="search"
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            label="Search My History.."
            class="float-right"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      
      <v-row class="mt-0">
        <v-col cols="12" xs="12" md="12">
          <v-card>
            <v-card-text class="px-0 pt-0">
              <v-skeleton-loader
                v-if="isLoading"
                type="table"
                class="mr-2 mt-6"
              >
              </v-skeleton-loader>
              <!-- table data here  -->
              <v-data-table
                v-else
                v-model="selected"
                :headers="headers"
                :items="quotations.items"
                :sort-by="['updated_at']"
                :sort-desc="[true]"
                :search="search"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.buyer_name`]="{ item }">
                  <span>{{item.buyer_name}}</span>
                </template>
                <template v-slot:[`item.job_id`]="{ item }">
                  <span>Job #{{ $tools.addLeadingZeros(item.job_id) }}</span>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                  <div v-if="item.updated_at !== null">
                    {{ $tools.dateTimeToStr(item.updated_at, "dd/MM/yyyy") }}
                  </div>
                </template>
                <template v-slot:[`item.file_count`]="{ item }">
                  <v-chip outlined small color="error">
                    {{ item.file_count }}
                  </v-chip>
                </template>
                <template v-slot:[`item.price`]="{ item }">
                  <span>{{ $tools.addCurrencySymbol(item.seller_amount) }}</span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip outlined small color="warning" class="text-capitalize">
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:[`item.delivery`]="{ item }">
                  <count-down :date="new Date(item.production_at)" color="error" class="mt-3"></count-down>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    small
                    :to="`/quotation-detail?uuid=${item.id}`"
                    class="primary rounded-pill"
                  >Upload</v-btn>
                </template>
              </v-data-table>
            </v-card-text>

            <v-card-actions class="px-0">
              <div v-if="!quotations.total"></div>
              <v-row v-else>
                <v-col class="py-0" cols="12" md="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" offset-md="3">
                  <v-pagination
                    class="text-center"
                    v-model="page"
                    :length="Math.ceil(quotations.total / selectRow)"
                    @input="handlePageChange"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                  <v-select
                    v-model="selectRow"
                    :items="rows"
                    hide-details="auto"
                    class="px-3"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mdiMagnify } from '@mdi/js'
import {
  LOAD_ALL_QUOTATION,
  LOAD_QUOTATION_BY_ID,
  REFRESHTOKEN
} from "@/store/_actiontypes";
import validations from "@/utils/validations";
import CountDown from "@/components/common/CountDown";


export default {
  components: {
    CountDown,
  },

  computed: {
    ...mapState({
      quotations: (state) => state.quotation.all,
      user: (state) => state.account.user
    }),
    ...mapGetters("loader", ["loading"]),
  },
  mounted(){
    this.getAllQuotation();
  },
  data(){
    return {
      search: "",
      icons: {
        mdiMagnify,
      },
      isLoading: false,
      states: [
        {state: "Status", value: ""},
        {state: "Requested", value: "requested"},
        {state: "Accepted", value: "accepted"},
        {state: "Processing", value: "processing"},
        {state: "Completed", value: "completed"},
        {state: "Rejected", value: "rejected"},
        {state: "Deleted", value: "deleted"},
      ],
      dates: [],
      selected: [],
      page: 1,
      selectRow: 10,
      rows: [5, 10, 20, 50, 100],
      ...validations,
      headers: [
        {
          text: "Buyer Name",
          value: "buyer_name",
          align: "start",
          sortable: true
        },
        {
          text: "Job ID#",
          value: "job_id",
          align: "start",
          sortable: true
        },
        {
          text: "Created Date",
          value: "updated_at",
          align: "start",
          sortable: true
        },
        {
          text: "Total Image",
          value: "file_count",
          align: "center",
          sortable: true
        },
        {
          text: "Price",
          value: "price",
          align: "center",
          sortable: true
        },
        {
          text: "Status",
          value: "status",
          align: "start"
        },
        {
          text: "Delivery Time",
          value: "delivery",
          align: "center"
        },
        {
          text: "ACTIONS",
          value: "action",
          align: "start"
        },
      ],
    };
  },
  watch: {
    selectRow(){
      this.page = 1;
      this.getAllQuotation();
    }
  },
  methods: {
    ...mapActions("quotation", [LOAD_ALL_QUOTATION, LOAD_QUOTATION_BY_ID]),
    ...mapActions("account", [REFRESHTOKEN]),

    getAllQuotation(){
      this.isLoading = true;
      if(this.$tools.timeToNextToken() < 300){
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then((response) => {
          this.getAllQuotationFunction();
        }, (error) => {
          error
        });
      } else {
        this.getAllQuotationFunction();
      }
    },
    getAllQuotationFunction(){
      this.LOAD_ALL_QUOTATION({
        start_date: "",
        end_date: "",
        job_status: "processing",
        per_page: this.selectRow,
        page: this.page,
      }).then((response) => {
        this.isLoading = false;
      }, (error) => {
        this.isLoading = false;
      });
    },
    handlePageChange(value){
      this.page = value
      this.getAllQuotation();
    },
  },
};
</script>
